.error{
    color: red !important;
}
.errorTag{
    color: red !important;
}
.subjectRow{
    border: 1px solid lightgray !important;
    padding: 10px 0px 10px 10px;
    margin-top: 10px;
}
span{
    color: black !important;
    font-size: 12px !important;
}
.note{
    font-size: 10px !important;
}