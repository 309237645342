.tableBody {
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  padding: 30px;
  background-color: white;
  border-radius: 16px;
}
.tableHead {
  font-size: 13px !important;
  font-weight: 600 !important;
}
.paperBody {
  box-shadow: none !important;
}
.search {
  outline: none;
  height: 34px;
  border: 1px solid lightgray;
  width: 220px;
  padding-left: 10px;
}
.paginationLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 19px;
}
.tableColData {
  font-size: 13px !important;
}
.selection {
  outline: none;
  height: 30px;
  border: 1px solid lightgray;
  width: 80px;
  margin-left: 2px;
}
.rowCount-div {
  text-align: right;
}
.root {
  margin-top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-filter-div {
  display: flex;
}
.search-div {
  padding: 6px;
  text-align: left;
}
.btn {
  border-radius: 0% !important;
}
.file {
  margin-left: 70%;
}
.error {
  color: red;
  margin-top: 5px;
  font-size: 0.9em;
}
.success {
  margin-top: 5px;
  font-size: 0.9em;
}

.ql-editor {
  min-height: 200px;
}

.ql-toolbar {
  background-color: #eaecec;
  padding: 5px  ;
} 
.actionsMinWidth{
  min-width: 80px;
}
.point{
  cursor: pointer;
}
.optionsContainer{
  display: flex;
}
.optionsContainer > :first-child{
  flex: 1 1 10%;
}
