body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 60px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.showMenu {
  transition: all 0.5s;
  transform: translateX(-100px);
  animation-name: slidein;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.sub-menu-animation {
  transition: all 0.5s;
  transform: translateX(-230px);
  animation-name: slidein;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#iconMenuDropdown,
#iconProfile {
  display: block;
}
@keyframes slidein {
  to {
    transform: translateX(0px);
  }
}

.cursor-pointer {
  cursor: pointer !important;
}
