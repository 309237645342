@font-face {
  font-family: 'Simple Line';
  font-style: normal;
  font-weight: normal;
  src: url('./simple-line-icons/fonts/Simple-Line-Icons.ttf') format('opentype');
}
@font-face {
  font-family: 'Icon Mind';
  font-style: normal;
  font-weight: normal;
  src: url('./iconsmind-s/font/iconsminds.ttf') format('opentype');
}
