// vendor minfied-css-files
@import "assets/css/main.css";
@import "assets/css/vendor/bootstrap.min";
@import "assets/css/vendor/baguetteBox.min";
@import "assets/css/vendor/bootstrap-datepicker3.min";
@import "assets/css/vendor/bootstrap-float-label.min";
@import "assets/css/vendor/bootstrap-stars";
@import "assets/css/vendor/bootstrap-tagsinput";
@import "assets/css/vendor/bootstrap.rtl.only.min";
@import "assets/css/vendor/component-custom-switch.min";
@import "assets/css/vendor/cropper.min";
@import "assets/css/vendor/dataTables.bootstrap4.min";
@import "assets/css/vendor/datatables.responsive.bootstrap4.min";
@import "assets/css/vendor/dropzone.min";
@import "assets/css/vendor/fullcalendar.min";
@import "assets/css/vendor/glide.core.min";
@import "assets/css/vendor/nouislider.min";
@import "assets/css/vendor/quill.bubble";
@import "assets/css/vendor/quill.snow";
@import "assets/css/vendor/select2-bootstrap.min";
@import "assets/css/vendor/select2.min";
@import "assets/css/vendor/slick";
@import "assets/css/vendor/smart_wizard.min";
@import "assets/css/vendor/video-js";
@import "assets/css/vendor/perfect-scrollbar";

// import remaining-css-files
@import "assets/font/simple-line-icons/css/simple-line-icons";
@import "assets/font/iconsmind-s/css/animation";
@import "assets/font/iconsmind-s/css/iconsminds-codes";
@import "assets/font/iconsmind-s/css/iconsminds-embedded";
@import "assets/font/iconsmind-s/css/iconsminds";

// fonts
@import "assets/font/font.scss";
