.radio {
  padding: 0.5rem;
  display: inline;
  margin-left: 10%;
}
.custom-control-label {
  padding-right: 20% !important;
}
.card-right {
  margin-left: 50%;
}
.selectors {
  display: flex;
  flex-direction: row;
}
.topic {
  margin-left: 2%;
}
.question {
  margin-top: 2%;
  cursor: pointer;
}
.card-body-super {
  background-color: darkgrey;
}
.icon {
  display: flex;
  flex-direction: row;
  margin-left: 86%;
  margin-top: 6px;
}
span {
  display: inline-block;
  vertical-align: middle;
}
.delete-icon {
  margin-left: 90%;
}
.delete-icon .delete {
  display: inline-block;
  vertical-align: middle;
}
.visible-icon,
.add-icon {
  cursor: pointer;
}
.add-icon {
  margin-left: 12px;
}
li {
  list-style: none;
}
.super-card {
  margin-top: 2%;
  background-color: #f5f5f5 !important;
}
.vimeo {
  display: flex;
  flex-direction: row;
  margin-top: 4%;
  margin-left: 35%;
}
.create-vimeo {
  margin-left: 4px;
}
.vimeo-error {
  margin-left: 36%;
}
.delete {
  cursor: pointer;
}
.file {
  margin-left: 84%;
  margin-top: 15%;
}

.card-header {
  padding: 0.75rem 1.25rem !important;
  margin-bottom: 0 !important;
  background-color: rgba(0, 0, 0, 0.03) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}
.quiz-button {
  margin-left: 40%;
}
.row-boxes {
  display: grid;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  grid-auto-flow: column;
  gap: 5%;
}
.videoImage, .thumbnail {
  width: 120px;
  height: 70px;
}
.goto {
  margin-left: 40%;
}
