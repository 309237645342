.has-float-label {
  display: block;
  position: relative;
}
.has-float-label label,
.has-float-label > span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 90%;
  opacity: 1;
  top: -0.4em;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
}
.has-float-label label::after,
.has-float-label > span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 5px;
  top: 3px;
  left: -0.2em;
  right: -0.2em;
  z-index: -1;
}
.has-float-label .form-control::-webkit-input-placeholder {
  opacity: 1;
}
.has-float-label .form-control::-moz-placeholder {
  opacity: 1;
}
.has-float-label .form-control:-ms-input-placeholder {
  opacity: 1;
}
.has-float-label .form-control::placeholder {
  opacity: 1;
}
.has-float-label
  .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}
.has-float-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}
.has-float-label
  .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}
.has-float-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}
.has-float-label .form-control:placeholder-shown:not(:focus) + * {
}
.input-group .has-float-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.input-group .has-float-label .form-control {
  width: 100%;
}
.input-group .has-float-label:not(:last-child),
.input-group .has-float-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}
.input-group .has-float-label:not(:first-child),
.input-group .has-float-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}













.form-group.has-top-label .form-control, .form-group.has-top-label .bootstrap-tagsinput {
  padding: 1.7rem 0.75rem 0.5rem 0.75rem;
}

.has-top-label {
  display: block;
  position: relative;
}
.has-top-label label,
.has-top-label > span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 76%;
  opacity: 1;
  top: 0.7rem;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
  font-weight: 600;
}
.has-top-label label::after,
.has-top-label > span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 2px;
  top: 50%;
  left: -0.2em;
  right: -0.2em;
  z-index: -1;

}
.has-top-label .form-control::-webkit-input-placeholder {
  opacity: 1;
}
.has-top-label .form-control::-moz-placeholder {
  opacity: 1;
}
.has-top-label .form-control:-ms-input-placeholder {
  opacity: 1;
}
.has-top-label .form-control::placeholder {
  opacity: 1;
}
.has-top-label
  .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}
.has-top-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}
.has-top-label
  .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}
.has-top-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}

.input-group .has-top-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.input-group .has-top-label .form-control {
  width: 100%;
}
.input-group .has-top-label:not(:last-child),
.input-group .has-top-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
}
.input-group .has-top-label:not(:first-child),
.input-group .has-top-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.form-group.has-top-label .form-control, .form-group.has-top-label .bootstrap-tagsinput {
  height: calc(3.3rem + 2px);
}

.form-group.has-top-label textarea.form-control {
  min-height: calc(3.3rem + 2px);
  height: initial;
}

.form-group.has-top-label select.form-control:not([size]):not([multiple]) {
  height: calc(3.3rem + 2px);
  padding: 1.7rem 0.75rem 0.5rem 0.5rem;
}