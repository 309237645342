.dropdown {
  height: 100% !important;
}
.search-div {
  padding: 6px;
  display: flex;
  flex-direction: row;
}
.filter-div {
  margin-left: 5%;
}
.edit,
.publish,
.draft {
  cursor: pointer;
  margin-left: 5%;
}

.paper {
  background-color: white;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  padding: 46px 30px;
  width: 40%;
  border-radius: 5px;
  height: 85%;
  overflow: scroll;
}
.modal {
  display: flex;
  align-items: center;
  padding: 2px;
  justify-content: center;
}

.test-modal{
  height: 390px !important;
  overflow: auto !important;
}